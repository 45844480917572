import * as PIXI from 'pixi.js';
import { Player } from './game';

export const SCORE_BOARD_LANDSCAPE_HEIGHT = 60;

export class ScoreBoard {
  private textNode: PIXI.Text;
  private topPlayerScore = 0;
  private bottomPlayerScore = 0;
  private isPortraitMode: boolean;
  readonly boardTopBuffer: number;

  constructor(private readonly app: PIXI.Application) {
    this.textNode = new PIXI.Text('',
        {fontFamily : 'Arial', fontSize: 18, fill : 0xffffff, align : 'left'});
    app.stage.addChild(this.textNode);

    this.isPortraitMode = app.view.height > app.view.width;
    if (this.isPortraitMode) {
      this.textNode.angle = 90;
      this.textNode.x = app.view.width - this.textNode.height;
      this.boardTopBuffer = 0;
    } else {
      this.textNode.y = 20;
      this.boardTopBuffer = SCORE_BOARD_LANDSCAPE_HEIGHT;
    }
    this.updateText();
  }

  private updateText() {
    const textValue = `${this.topPlayerScore} — ${this.bottomPlayerScore}`;
    this.textNode.text = textValue;
    if (this.isPortraitMode) {
      this.textNode.y = this.app.view.height / 2 - (this.textNode.width / 2);
    } else {
      this.textNode.x = this.app.view.width / 2 - this.textNode.width / 2;
    }
  }

  increment(player: Player) {
    if (player === Player.TOP) {
      this.topPlayerScore++;
    } else {
      this.bottomPlayerScore++;
    }
    this.updateText();
  }
}
