import * as PIXI from 'pixi.js';
import { GameBounds, isMouseMoveData, Player, RectangleCoordinates, TickData } from './game';

const Y_BUFFER = 10;
const PADEL_WIDTH = 80;
const HALF_PADEL_WIDTH = PADEL_WIDTH / 2;
const PADEL_HEIGHT = 10;
const LIFT_AMOUNT = 90;

const MAX_AI_SPEED = 4;

export class Padel implements RectangleCoordinates {
  private aiEnabled = false;
  private sprite: PIXI.Graphics;
  private maxPadelX: number;
  private lifted = false;

  constructor(app: PIXI.Application,
              private readonly player: Player,
              private readonly bounds: GameBounds) {
    this.sprite = new PIXI.Graphics();
    this.sprite.beginFill(0xffffff);
    this.sprite.drawRect(0, 0, PADEL_WIDTH, PADEL_HEIGHT);
    app.stage.addChild(this.sprite);

    this.maxPadelX = this.bounds.right - PADEL_WIDTH;

    this.sprite.x = this.maxPadelX / 2;
    this.sprite.y = this.player === Player.TOP ?
        Y_BUFFER + this.bounds.top :
        this.bounds.bottom - Y_BUFFER - PADEL_HEIGHT;
  }

  /**
   * Move the padel LIFT_AMOUNT inward so that the user can still see their
   * padels when their finger is on the screen.
   */
  liftForTouch() {
    if (this.lifted) {
      return;
    }
    if (this.player === Player.TOP) {
      this.sprite.y += LIFT_AMOUNT;
    } else {
      this.sprite.y -= LIFT_AMOUNT;
    }
    this.lifted = true;
  }

  enableAi(enabled: boolean) {
    this.aiEnabled = enabled;
  }

  tick(tickData: TickData) {
    if (this.aiEnabled) {
      this.runAi(tickData);
    } else {
      let newX: number;
      const move = tickData.move;
      if (isMouseMoveData(move)) {
        newX = this.sprite.x + move.currentX - move.lastX;
      } else {
        const touchX = this.player === Player.TOP ? move.topX : move.bottomX;
        if (!touchX) {
          return;
        }
        newX = touchX - HALF_PADEL_WIDTH;
      }
      this.sprite.x = this.clampX(newX);
    }
  }

  private runAi(tickData: TickData) {
    // Perfect:
    // this.sprite.x = this.clampX(tickData.ballX - HALF_PADEL_WIDTH);
    const goal = this.clampX(tickData.ballX - HALF_PADEL_WIDTH);
    const distance = goal - this.sprite.x;
    const clampedDistance = Math.max(-MAX_AI_SPEED, Math.min(MAX_AI_SPEED, distance));
    this.sprite.x += clampedDistance;
  }

  private clampX(x: number): number {
    return Math.max(0, Math.min(this.maxPadelX, x));
  }

  get left(): number {
    return this.sprite.x;
  }

  get right(): number {
    return this.sprite.x + PADEL_WIDTH;
  }

  get width(): number {
    return PADEL_WIDTH;
  }

  get centerX(): number {
    return this.sprite.x + HALF_PADEL_WIDTH;
  }

  get top(): number {
    return this.sprite.y;
  }

  get bottom(): number {
    return this.sprite.y + PADEL_HEIGHT;
  }
}
