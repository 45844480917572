import * as PIXI from 'pixi.js';
import { GameBounds, RectangleCoordinates, TickData } from './game';

const BALL_RADIUS = 5;

const HALF_PI = Math.PI / 2;

function withinXBounds(ballX: number, rect: RectangleCoordinates) {
  return ballX >= rect.left && ballX <= rect.right;
}

export class Ball {
  private sprite: PIXI.Graphics;
  private minX: number;
  private maxX: number;
  private xVelocity: number;
  private yVelocity: number;

  constructor(app: PIXI.Application, private readonly bounds: GameBounds) {
    this.sprite = new PIXI.Graphics();
    this.sprite.beginFill(0xffffff);
    this.sprite.drawCircle(0, 0, BALL_RADIUS);

    app.stage.addChild(this.sprite);

    this.minX = BALL_RADIUS;
    this.maxX = bounds.right - BALL_RADIUS;

    this.reset();
  }

  reset() {
    this.xVelocity = (Math.random() - 0.5) * 6;
    this.yVelocity = Math.random() < 0.5 ? 5 : -5;

    this.sprite.x = (this.bounds.left + this.bounds.right) / 2;
    this.sprite.y = this.bounds.middleY;
  }

  tick(tickData: TickData, topPadel: RectangleCoordinates, bottomPadel: RectangleCoordinates) {
    let newY = this.sprite.y + this.yVelocity * tickData.deltaTime;
    let contactDeltaTime: number;
    let targetPadel: RectangleCoordinates;
    if (this.yVelocity > 0) {
      contactDeltaTime =
          (bottomPadel.top - this.bottom) / this.yVelocity;
      targetPadel = bottomPadel;
    } else {
      contactDeltaTime =
          (topPadel.bottom - this.top) / this.yVelocity;
      targetPadel = topPadel;
    }

    // TODO: We could get real fancy here and let the edge of the ball bounce
    // off the corner of the padel or the sides of the padel.

    if (contactDeltaTime > 0 && contactDeltaTime < tickData.deltaTime) {
      const [contactX] = this.calculateNewX(contactDeltaTime);
      if (withinXBounds(contactX, targetPadel)) {
        // A hit has occured!
        if (targetPadel === topPadel) {
          newY = Math.abs(targetPadel.bottom - newY) + targetPadel.bottom;
        } else if (targetPadel === bottomPadel) {
          newY = targetPadel.top - Math.abs(targetPadel.top - newY);
        }
        const centerDistance = this.x - targetPadel.centerX;
        const direction = centerDistance < 0 ? -1 : 1;
        const centerDistanceRatio = Math.abs(centerDistance / targetPadel.width);

        this.xVelocity =
            (Math.pow((1 + centerDistanceRatio * 6), 1.6) - 1) * direction;
        this.yVelocity *= -1;
      }
    }

    this.sprite.y = newY;
    const [newX, bouncedX] = this.calculateNewX(tickData.deltaTime);
    this.sprite.x = newX;
    if (bouncedX) {
      this.xVelocity *= -1;
    }
  }

  /**
   * Returns new x and true if it bounced.
   */
  private calculateNewX(deltaTime: number): [number, boolean] {
    let newX = this.sprite.x + this.xVelocity * deltaTime;
    let bounced = false;
    if (this.sprite.x < this.minX) {
      newX = Math.abs(this.minX - this.sprite.x) + this.minX;
      bounced = true;
    } else if (this.sprite.x > this.maxX) {
      newX = this.maxX - Math.abs(this.maxX - this.sprite.x);
      bounced = true;
    }
    return [newX, bounced];
  }

  /** The middle-x coordinate. */
  get x(): number {
    return this.sprite.x;
  }

  get left(): number {
    return this.sprite.x - BALL_RADIUS / 2;
  }

  get right(): number {
    return this.sprite.x + BALL_RADIUS / 2;
  }

  get top(): number {
    return this.sprite.y - BALL_RADIUS / 2;
  }

  get bottom(): number {
    return this.sprite.y + BALL_RADIUS / 2;
  }
}
