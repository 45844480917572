import * as PIXI from 'pixi.js';
import {Padel} from './padel';
import {Ball} from './ball';
import { createTouchMoveData, isMouseMoveData, MouseMoveData, MoveData, Player, TickData, TouchMoveData, isTouchMoveData, GameBounds } from './game';
import { ScoreBoard } from './score-board';

const app = new PIXI.Application({ width: window.innerWidth, height: window.innerHeight });
document.body.appendChild(app.view);

if (screen?.orientation?.lock) {
  // This doesn't work on iPhone, so I will need to deal with it.
  screen.orientation.lock('portrait-primary');
}

const scoreBoard = new ScoreBoard(app);

const GAME_BOUNDS: GameBounds = {
  top: scoreBoard.boardTopBuffer,
  bottom: app.view.height,
  middleY: (app.view.height - scoreBoard.boardTopBuffer) / 2  + scoreBoard.boardTopBuffer,
  left: 0,
  right: app.view.width,
}

const topPadel = new Padel(app, Player.TOP, GAME_BOUNDS);
const bottomPadel = new Padel(app, Player.BOTTOM, GAME_BOUNDS);
const ball = new Ball(app, GAME_BOUNDS);

app.view.addEventListener('touchstart', e => {
  initGameLoop(e);
});

app.view.addEventListener('mousedown', e => {
  initGameLoop(e);
}, { once: true });

let hasInitialized = false;
let gameHasStarted = false;
function initGameLoop(startEvent: MouseEvent|TouchEvent) {
  if (hasInitialized) {
    return;
  }
  hasInitialized = true;

  const isMouseGame = startEvent instanceof MouseEvent;

  topPadel.enableAi(isMouseGame);

  let moveData: MoveData;
  if (isMouseGame) {
    moveData = { lastX: startEvent.offsetX, currentX: startEvent.offsetX };
    gameHasStarted = true;
  } else {
    moveData = createTouchMoveData(startEvent.touches, GAME_BOUNDS)!;
  }

  if (isMouseGame) {
    app.view.addEventListener('mousemove', e => {
      (moveData as MouseMoveData).currentX = e.offsetX;
    });
  } else {
    app.view.addEventListener('touchmove', e => {
      const newMoveData = createTouchMoveData(e.touches, GAME_BOUNDS);
      if (newMoveData) {
        moveData = newMoveData;
      }
    });
  }

  app.ticker.add((deltaTime: number) => {
    const tickData: TickData = {
      deltaTime,
      ballX: ball.x,
      move: moveData,
    };

    topPadel.tick(tickData);
    bottomPadel.tick(tickData);

    if (!gameHasStarted && isTouchMoveData(moveData)) {
      gameHasStarted = moveData.topX !== undefined && moveData.bottomX !== undefined;
      if (moveData.topX) {
        topPadel.liftForTouch();
      }
      if (moveData.bottomX) {
        bottomPadel.liftForTouch();
      }
    }

    if (gameHasStarted) {
      ball.tick(tickData, topPadel, bottomPadel);

      if (ball.bottom < 0 || ball.top > app.view.height) {
        const winner = ball.bottom < 0 ? Player.BOTTOM : Player.TOP;
        scoreBoard.increment(winner);
        ball.reset();
      }
    }

    if (isMouseMoveData(moveData)) {
      moveData.lastX = moveData.currentX;
    }
  });
}
