/**
 * Aribtrary data structures for holding game state.
 */

export enum Player {
  TOP = 1,
  BOTTOM = 2,
}

export interface MouseMoveData {
  lastX: number;
  currentX: number;
}

export interface TouchMoveData {
  topX?: number;
  bottomX?: number;
}

export function createTouchMoveData(touches: TouchList, bounds: GameBounds): TouchMoveData|null  {
  if (touches.length < 2) {
    const touch = touches[0];
    if (touch.clientY > bounds.middleY) {
      return { bottomX: touch.clientX };
    } else {
      return { topX: touch.clientX };
    }
  }
  let top = touches[0];
  let bottom = touches[0];
  for (let i = 1; i < touches.length; i++) {
    const touch = touches[i];
    if (touch.clientY < top.clientY) {
      top = touch;
    } else if (touch.clientY > bottom.clientY) {
      bottom = touch;
    }
  }
  return { topX: top.clientX, bottomX: bottom.clientX };
}

export type MoveData = MouseMoveData|TouchMoveData;

export function isMouseMoveData(moveData: MoveData): moveData is MouseMoveData {
  return (moveData as MouseMoveData).lastX !== undefined;
}

export function isTouchMoveData(moveData: MoveData): moveData is TouchMoveData {
  return !isMouseMoveData(moveData);
}

export interface GameBounds {
  top: number;
  bottom: number;
  middleY: number;
  left: number;
  right: number;
}

export interface TickData {
  deltaTime: number;
  ballX: number;
  move: MoveData;
}

export interface RectangleCoordinates {
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly bottom: number;
  readonly width: number;
  readonly centerX: number;
}
